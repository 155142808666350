<template>
  <v-app>
    <NavBar />
    <v-main>
        <router-view />
    </v-main>
    <Footer />
    <WhatsAppButton />
  </v-app>
</template>

<script>
import NavBar from '@/components/landing/NavBar'
import Footer from '@/components/landing/Footer'
import { mapActions } from 'vuex'
import WhatsAppButton from '@/components/landing/WhatsAppButton'

export default {
  name: 'LandingLayout',
  components: { NavBar, Footer, WhatsAppButton },
  methods: {
    ...mapActions('landing', ['fetchClientes']),
    ...mapActions('landing', ['fetchServicios'])

  },
  mounted () {
    this.fetchClientes()
    this.fetchServicios()
  }
}
</script>

<style>
.v-application a {
  color: inherit !important;
}
</style>
