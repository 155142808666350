const moduleFiles = import.meta.globEager('./**/*.js')
const modules = {}

Object.keys(moduleFiles).forEach(filePath => {
  if (filePath === './index.js') return

  // Obtiene el nombre del módulo y la ruta
  const path = filePath.replace(/(\.\/|\.js)/g, '')
  const [moduleName, imported] = path.split('/')

  // Verifica si el módulo ya ha sido inicializado
  if (!modules[moduleName]) {
    modules[moduleName] = {
      namespaced: true
    }
  }

  // Importa el módulo y lo agrega al objeto de módulos
  modules[moduleName][imported] = moduleFiles[filePath].default
})

export default modules