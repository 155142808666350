import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  treeShake: true,
  theme: {
    // options: {
    //   customProperties: true
    // },
    themes: {
      light: {
        // primary: '#ee44aa',
        primary: '#BE0105',
        secondary: '#424242',
        // accent: '#82B1FF',
        accent: '#383838',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'md'
  }
})
