import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '../router'

const GOOGLE_ANALYTICS_PROPERTY_ID = "G-SYPLKKMNHP"

export default Vue.use(
    VueGtag,
    {
        appName: "Puccasky",
        pageTrackerScreenviewEnabled: true,
        config: {
            id: GOOGLE_ANALYTICS_PROPERTY_ID
        }
    },
    router
)