export const set = property => (state, payload) => (state[property] = payload)

export const toggle = property => state => (state[property] = !state[property])

export const append = property => (state, payload) => {
  if (Array.isArray(state[property])) {
    console.error(`${property} is not an array`)
    return
  }
  return state[property].append(payload)
}

export const push = property => (state, payload) => {
  if (state[property].push) {
    return state[property].push(payload)
  } else {
    console.error(`${property} has no push method`)
  }
}
