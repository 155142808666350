import axios from 'axios'

export default {
  fetchProcesses: async ({ commit }) => {
    const processesResponse = await axios.get('api/processes/')
    const processes = processesResponse.data
    commit('SET_PROCESSES', processes)
  },
  fetchCurrentProcess: async ({ commit }, id) => {
    const processResponse = await axios.get(`api/processes/${id}/`)
    const process = processResponse.data
    commit('SET_CURRENT_PROCESS', process)
  },
  clearCurrentProcess: async ({ commit }) => {
    commit('SET_CURRENT_PROCESS', null)
  },
  updateCurrentProcess: async ({ commit, state }, payload) => {
    const processResponse = await axios.patch(`api/processes/${state.currentProcess.id}/`, payload)
    const process = processResponse.data
    commit('SET_CURRENT_PROCESS', process)
  },
  requestNewProcess: async ({ commit }, payload) => {
    const processResponse = await axios.post('api/processes/', payload)
    const process = processResponse.data
    commit('PUSH_PROCESS', process)
    return process
  },
  fetchParameters: async ({ commit }) => {
    const parametersResponse = await axios.get('api/parameters/')
    const parameters = parametersResponse.data
    commit('SET_PARAMETERS', parameters)
    commit('SET_ACTIVE_PARAMETER', parameters[0])
  },
  loadImageToProcess: async ({ commit, state }, payload) => {
    const processResponse = await axios.post(`api/processes/${state.currentProcess.id}/load_image/`, payload)
    const process = processResponse.data
    commit('SET_CURRENT_PROCESS', process)
  },
  callToProcess: async ({ commit, state }, payload) => {
    const processResponse = await axios.post(`api/processes/${state.currentProcess.id}/process/`)
    const process = processResponse.data
    commit('SET_CURRENT_PROCESS', process)
  },
  setActiveParameter: async ({ commit }, payload) => {
    const ParametersResponse = await axios.get('api/parameters/')
    const activeParameter = ParametersResponse.data.find(item => item.id === payload)
    commit('SET_ACTIVE_PARAMETER', activeParameter)
  },
  setImagesType: async ({ commit }) => {
    const imagesTypeResponse = await axios.get('api/image_types/')
    const imagesType = imagesTypeResponse.data
    commit('SET_IMAGES_TYPE', imagesType)
  }
}
