
<div>
  <v-app-bar
    app
    color="white"
    dark
    class="appBar"
    :height="smAndDown ? '60': '80'"
  >
    <div class="d-flex">
      <v-btn
        icon
        class="appBar__menu"
        @click.stop="drawerWrapper = !drawerWrapper"
      >
        <img src="@/assets/img/icon/menu.svg" />
      </v-btn>

      <router-link  :to="{name: 'Home'}">
        <img class="appBar__logo"  src="@/assets/img/logos/logo-solo-pucca.png" height="50px" alt="Puccasky Logo">
      </router-link>
    </div>

    <v-spacer></v-spacer>

    <div class="d-flex align-center">
      <v-btn
        icon
        class="appBar__bell"
      >
        <v-badge
          color="#BE0105"
          dot
          overlap
        >
          <img
            style="height: 25px;"
            contain
            src="@/assets/img/icon/bell.svg"
          />
        </v-badge>
      </v-btn>

      <v-avatar
        class="appBar__avatar"
        color="#BE0105"
        size="30"
      ></v-avatar>
    </div>
  </v-app-bar>
  <the-drawer
    v-model="drawerWrapper"
  />
</div>
