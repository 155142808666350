
<v-btn
  fab
  fixed
  bottom
  right
  color="green"
  height="64"
  min-width="64"
  :href="whatsappUrl"
  target="_blank"
>
  <img
    class="w-9"
    style="color: white"
    src="@/assets/img/icon/whatsapp.svg"
    alt="Icon"
  />
</v-btn>
