// import foto from '../../../assets/img/foto-prueba.svg'

export default {
  currentProcessReadyToProcess (state) {
    if (state.currentProcess) {
      const imagesToLoad = state.currentProcess.parameter.bandsParameter.length
      return state.currentProcess.imageType !== null && state.currentProcess.images.length === imagesToLoad
    }
    return false
  },
  // nombreUsuario (state) {
  //   if (state.user.first_name || state.user.last_name) {
  //     return `${state.user.first_name} ${state.user.last_name}`
  //   }
  //   return state.user.username
  // }
  currentProcessState: state => state.currentProcess.state === 'Procesado'
}
