import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLayout from '@/views/auth/AuthLayout'
import LandingLayout from '@/views/landing/LandingLayout'
import AppLayout from '@/views/app/AppLayout'
import { ifAuthenticated, ifNotAuthenticated, ifProcessed, isValid } from '@/router/guards'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LandingLayout,
    children: [
      {
        path: '',
        name: 'Inicio',
        component: () => import('../views/landing/Inicio.vue')
      },
      {
        path: '/contacto',
        name: 'Contacto',
        component: () => import('../views/landing/Contacto.vue')
      },
      {
        path: '/proyectos',
        name: 'Proyectos',
        component: () => import('../views/landing/Proyectos.vue')
      },
      {
        path: '/puccasky',
        name: 'Nosotros',
        component: () => import('../views/landing/Nosotros.vue')
      },
      {
        path: '/servicios',
        component: () => import('../views/landing/ServiciosPage.vue'),
        children: [
          {
            path: '',
            name: 'Servicios',
            component: () => import('../views/landing/Servicios.vue')
          },
          {
            path: ':servicio',
            name: 'ServicioDetalle',
            props: true,
            component: () => import('../views/landing/ServicioDetalle.vue'),
            beforeEnter: isValid
          }
        ]
      }
    ]
  },

  {
    path: '/app',
    component: AppLayout,
    redirect: { name: 'Home' },
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'inicio',
        name: 'Home',
        component: () => import('../views/app/Home.vue')
      },
      {
        path: 'process',
        component: () => import('../views/app/ProcessLayout'),
        name: 'ProcessLayout',
        children: [
          {
            path: ':id',
            component: () => import('../views/app/Process'),
            name: 'Process'
          },
          {
            path: ':id/resultados',
            component: () => import('../views/app/Results'),
            name: 'Results',
            beforeEnter: ifProcessed,
            children: [
              {
                path: 'clasificacion-por-zonas-y-clases',
                component: () => import('../views/app/Clasification'),
                name: 'Classification'
              },
              {
                path: 'estadisticas-generales',
                component: () => import('../views/app/Statistics'),
                name: 'Statistics'
              },
              {
                path: 'resultados-en-funcion-a-normativa',
                component: () => import('../views/app/Normative'),
                name: 'Normative'
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/app/auth',
    component: AuthLayout,
    redirect: { name: 'Welcome' },
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: '/',
        name: 'Welcome',
        component: () => import('../views/auth/Welcome'),
        meta: {
          authForm: 'Iniciar Sesion'
        }
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/auth/Login'),
        meta: {
          authForm: 'Iniciar Sesion'
        }
      },
      {
        path: 'reestablecer-contrasena',
        name: 'PasswordRecovery',
        component: () => import('../views/auth/PasswordRecovery'),
        meta: {
          authForm: 'Reestablecer Contraseña'
        }
      },
      {
        path: 'establecer-contrasena/:uid/:token',
        name: 'PasswordSetUser',
        component: () => import('../views/auth/PasswordSet'),
        meta: {
          authForm: 'Establecer Contraseña'
        }
      }
    ]

  },

  {
    path: '/app/**',
    component: () => import('../views/404'),
  },

  {
    path: '/app/*',
    component: () => import('../views/404'),
  },

  {
    path: '**',
    component: () => import('../views/404'),
  }

]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
