<template>
  <v-app>
    <the-app-bar/>
    <v-main :style="cssProps">
      <v-slide-x-transition leave-absolute>
        <router-view />
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from '@/components/TheAppBar'

export default {
  name: 'AppLayout',
  components: {
    TheAppBar
  },
  computed: {
    cssProps () {
      if (this.$route.name === 'Results' || this.$route.name === 'Statistics' || this.$route.name === 'Classification' || this.$route.name === 'Normative') {
        return {
          backgroundImage: `url(${require('@/assets/img/background-results.svg')})`,
          backgroundPosition: 'right bottom',
          backgroundSize: '100% auto'
        }
      } else {
        return {
          background: '#F4F4F4'
        }
      }
    }
  }
}
</script>
