
<footer class="bg-white pt-8">
  <div class="container-fluid">
    <div
      class="flex lg:flex-wrap lg:justify-evenly lg:flex-row flex-col lg:px-4 md:px-8 px-4"
    >
      <div class="flex flex-col justify-center items-center lg:w-1/4 lg:px-4 mb-4 lg:mb-0">
        <img
          src="@/assets/img/logos/logo-completo.png"
          alt="PuccaSky Logo"
          class="mx-auto h-24"
        />
        <p class="text-black text-center text-sm">
          PuccaSky te ayudará a potenciar tus productos y/o servicios con la
          aplicación de la más avanzada tecnología y asesoría especializada.
        </p>
      </div>
      <div class="lg:w-1/4 lg:px-4 mb-4 lg:mb-0">
        <h4 class="text-red-600 mb-2 font-bold">Servicios</h4>
        <ul class="text-black font-semibold">
          <li
            v-for="servicio in getServicios"
            :key="servicio.id"
            class="lg:pl-2 p-1 text-sm hover:text-red-400 hover:underline"
          >
            <router-link
              :to="{
                name: 'ServicioDetalle',
                params: { servicio: servicio.url }
              }"
              >{{ servicio.nombre }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class=" lg:px-4 mb-4 lg:mb-0">
        <h4 class="text-red-600 mb-2 font-bold">Contáctanos</h4>
        <ul class="text-black">
          <li class="lg:pl-2 p-1 text-sm flex gap-1">
            <img class="w-6" src="@/assets/img/icon/home.svg" alt="Icon" />
            <span>Jr. Mariscal Miller 1030</span>
          </li>
          <li
            class="lg:pl-2 p-1 text-sm flex gap-1 hover:text-red-400 hover:underline"
          >
            <img class="w-6" src="@/assets/img/icon/phone.svg" alt="Icon" />
            <a href="tel:986740689">986 740 689</a>
          </li>
          <li
            class="lg:pl-2 p-1 text-sm flex gap-1 hover:text-red-400 hover:underline"
          >
            <img class="w-6" src="@/assets/img/icon/phone.svg" alt="Icon" />
            <a href="tel:956960900">956 960 900</a>
          </li>
          <li
            class="lg:pl-2 p-1 text-sm flex gap-1 hover:text-red-400 hover:underline"
          >
            <img
              class="w-6"
              src="@/assets/img/icon/envelope.svg"
              alt="Icon"
            />
            <a href="mailto:daniel_caballero@puccasky.com"
              >daniel_caballero@puccasky.com</a
            >
          </li>
          <li
            class="lg:pl-2 p-1 text-sm flex gap-1 hover:text-red-400 hover:underline"
          >
            <img
              class="w-6"
              src="@/assets/img/icon/envelope.svg"
              alt="Icon"
            />
            <a href="mailto:daniel_caball@outlook.com"
              >daniel_caball@outlook.com</a
            >
          </li>
          <li
            class="lg:pl-2 p-1 text-sm flex gap-1 hover:text-red-400 hover:underline"
          >
            <img
              class="w-6"
              src="@/assets/img/icon/facebook.svg"
              alt="Icon"
            />
            <a
              href="https://www.facebook.com/PuccaSkySAC/"
              target="_blank"
              rel="noopener"
              >facebook.com/PuccaSky</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="bg-gray-900 text-center mt-6 py-4">
      <p class="text-white copyrigth">Copyright © 2021 PuccaSky.com</p>
    </div>
  </div>
</footer>
