import axios from 'axios'

export default {

  login: async ({ commit }, payload) => {
    const tokenResponse = await axios.post('api/auth/login/', payload)
    const token = tokenResponse.data.key
    localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
  },
  logout: async ({ commit }) => {
    try {
      await axios.post('api/auth/logout/')
    } finally {
      localStorage.removeItem('token')
      commit('SET_TOKEN', null)
    }
  }

}
