import store from '../store'

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next({ name: 'Home' })
}

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next({ name: 'Welcome' })
}

export const ifProcessed = async (to, from, next) => {
  if (store.getters['processes/currentProcessState']) {
    next()
    return
  }
  next({ name: 'Home' })
}

export const isValid = async (to, from, next) => {
  // Llama al action para obtener los servicios del backend
  store.dispatch('landing/fetchServicios').then(() => {
    const servicio = to.params.servicio
    const servicios = store.getters['landing/getServicios']

    const serviceExist = servicios.find(serv => serv.url === servicio)
    // Verificar si el servicio existe
    if (servicio && serviceExist) {
      next() // Continuar con la navegación
    } else {
      next({ name: 'Servicios' }) // Redirigir a la página de Servicios si el servicio no existe
    }
  }).catch(error => {
    console.error('Error fetching servicios:', error)
    next(false) // Abortar la navegación en caso de error
  })
}
