import axios from 'axios'

export default {
  fetchServicios: async ({ commit }) => {
    try {
      const response = await axios.get('api/servicios/')
      const servicios = response.data // Suponiendo que la respuesta contiene un array de servicios
      commit('SET_SERVICIOS', servicios)
    } catch (error) {
      console.error('Error fetching servicios:', error)
    }
  },
  fetchClientes: async ({ commit }) => {
    try {
      const response = await axios.get('api/clientes/')
      const clientes = response.data
      commit('SET_CLIENTES', clientes)
    } catch (error) {
      console.error('Error fetching clientes:', error)
    }
  },
  fetchProyectos: async ({ commit }) => {
    try {
      const response = await axios.get('api/proyectos/')
      const proyectos = response.data
      commit('SET_PROYECTOS', proyectos)
    } catch (error) {
      console.error('Error fetching proyectos:', error)
    }
  }
}
