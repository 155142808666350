// import "./init"
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import googleAnalytics from './plugins/google-analytics'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './assets/css/styles.css'
import './assets/css/styles.scss'
import './plugins/axios'
import './plugins/vuealert'
import './plugins/moment'
import './plugins/apexchart'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  googleAnalytics,
  render: h => h(App)
}).$mount('#app')
