export default {
  // categorias: null,
  // preguntas: [],
  // categoriaSeleccionada: { id: 0, nombre: 'Global' },
  // publicidadesClases: [],
  processes: [],
  parameters: null,
  currentProcess: {},
  activeParameter: null,
  imagesType: null
}
