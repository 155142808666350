import { set, push } from '@/utils/vuex'

export default {
  SET_PROCESSES: set('processes'),
  PUSH_PROCESS: push('processes'),
  SET_PARAMETERS: set('parameters'),
  SET_CURRENT_PROCESS (state, payload) {
    state.currentProcess = payload
  },
  SET_ACTIVE_PARAMETER (state, payload) {
    state.activeParameter = payload
  },
  SET_IMAGES_TYPE (state, payload) {
    state.imagesType = payload
  }
}
