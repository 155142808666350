<template>
  <v-btn
    fab
    fixed
    bottom
    right
    color="green"
    height="64"
    min-width="64"
    :href="whatsappUrl"
    target="_blank"
  >
    <img
      class="w-9"
      style="color: white"
      src="@/assets/img/icon/whatsapp.svg"
      alt="Icon"
    />
  </v-btn>
</template>

<script>
export default {
  name: "WhatsAppButton",
  data() {
    return {
      numero: "51986740689",
      mensaje: "Hola Puccasky, quiero más información",
    };
  },
  computed: {
    whatsappUrl() {
      return `https://wa.me/${this.numero}?text=${encodeURIComponent(
        this.mensaje
      )}`;
    },
  },
};
</script>
