
<v-app>
  <v-main class="wrapper overflow-hidden">
    <v-container
      class="fill-height w-100"
      :class="smAndDown ? 'pa-0': ''"
      :fluid="!smAndDown"
    >
      <v-card
        class="card"
        :class="smAndDown ? 'ma-0': 'ma-auto'"
        :width="smAndDown ? '100%': '800'"
        :height="smAndDown ? '100%': '500'"
        flat
      >
        <v-row justify="center" align="center" :class="smAndDown ? 'h-100': 'fill-height'" class="m-0">
          <v-col cols="12" md="6" class="d-flex flex-column justify-center align-center">
            <div v-if="$route.name==='Welcome' || !smAndDown">
              <img
                style="height: 100px; max-width: 300px;"
                height="62"
                max-width="250"
                src="@/assets/img/logos/logo-completo.png"
              />
              <p class="pa-0 ma-0 card__slogan mt-5 text-center">
                Un nuevo punto de vista
              </p>
            </div>
            <div v-else class="d-flex justify-center align-center">
              <v-btn v-if="$route.name!=='Welcome'" icon absolute style="top: 23px; left: 25px;" @click="$router.go(-1)">
                <v-icon color="black">
                  arrow_back
                </v-icon>
              </v-btn>
              <p v-if="$route.name!=='Welcome'" class="card__header ma-0 pa-0 mt-5">
                {{ $route.meta.authForm }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="card__form d-flex justify-center align-center" align-self="stretch">
            <v-slide-x-transition leave-absolute>
              <router-view />
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</v-app>
