import axios from 'axios'
import store from '../store'
import router from '../router/index'

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.puccasky.com'
} else {
  // axios.defaults.baseURL = 'http://localhost:8000'
  axios.defaults.baseURL = 'https://api.puccasky.com'
}
axios.interceptors.request.use(async function (config) {
  if (store.state.token) {
    const token = store.state.token
    const authorization = `Token ${token}`
    config.headers.Authorization = authorization
    return config
  } else {
    config.headers.Authorization = null
    return config
  }
})

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx(no error) cause this function to trigger
  // Don't do anything
  return response
}, function (error) {
  // If your token has expired, clean the token in store and localstorage and redirect to the login
  if (error.response?.status === 401 && error.response?.data.detail === 'Token inválido.') {
    localStorage.removeItem('token')
    store.commit('SET_TOKEN', null)
    router.push({ name: 'Login' }).then()
  }
  return Promise.reject(error)
})
export default axios
