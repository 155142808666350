
<nav class="bg-white px-6 py-2 sticky top-0 z-50 shadow">
  <div class="container mx-auto flex justify-between items-center">
    <!-- Logo -->
    <div @click="handleNavigation">
      <router-link :to="{ name: 'Inicio' }">
        <img
          src="@/assets/img/logos/logo-completo.png"
          alt="Logo"
          class="h-20"
        />
      </router-link>
    </div>

    <!-- Menú -->
    <div class="flex-1 text-center hidden xl:block">
      <ul class="flex justify-center space-x-14 pr-6">
        <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Inicio' }" @click="handleNavigation">
          <router-link :to="{ name: 'Inicio' }">Inicio</router-link>
        </li>
        <li
          class="relative text-black hover:text-red-500"
          @mouseover="showSubmenu = true"
          @mouseleave="showSubmenu = false"
          :class="{ 'text-red-500': $route.name === 'Servicios' || $route.name === 'ServicioDetalle'}"
        >
          <router-link :to="{ name: 'Servicios' }">Servicios</router-link>
          <ul
            v-if="showSubmenu"
            class="absolute bg-white shadow-lg text-left rounded-lg p-2 w-48 overflow-y-auto max-h-56"
            @mouseover="showSubmenu = true"
            @mouseleave="showSubmenu = false"
          >
            <li
              v-for="servicio in getServicios"
              :key="servicio.id"
              class="text-black hover:text-red-500 my-5"
              :class="{ 'text-red-500': servicio.url === $route.params.servicio }"
            >
              <router-link
                :to="{ name: 'ServicioDetalle', params: { servicio: servicio.url } }"
                :servicio="servicio"
                >{{ servicio.nombre }}</router-link
              >
            </li>
          </ul>
        </li>
        <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Nosotros' }">
          <router-link :to="{ name: 'Nosotros' }">Puccasky</router-link>
        </li>
        <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Proyectos' }">
          <router-link :to="{ name: 'Proyectos' }">Proyectos</router-link>
        </li>
        <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Contacto' }">
          <router-link :to="{ name: 'Contacto' }">Contacto</router-link>
        </li>
      </ul>
    </div>

    <!-- Botón de Pida una cotización -->
    <div class="hidden xl:flex text-white justify-between gap-5">
      <router-link
        :to="{ name: 'Contacto' }"
        class="bg-red-700 px-4 py-2 font-bold rounded-full hover:bg-red-600 text-center"
      >
        Pida una cotización
      </router-link>
      <router-link
        :to="{ name: 'Login' }"
        class="bg-transparent border border-red-700 px-4 py-2 font-bold rounded-full hover:bg-red-600 text-center boton-login-landing"
      >
        Inicia Sesión
      </router-link>
    </div>

    <!-- Botón de menú para dispositivos móviles -->
    <div class="xl:hidden">
      <button @click="toggleMenu" class="text-black focus:outline-none">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>
    </div>
  </div>

  <!-- Menú desplegable para dispositivos móviles -->
  <div v-if="isMobileMenuOpen" class="xl:hidden inline-block">
    <ul class="bg-white mt-2 p-2 space-y-3 pb-5">
      <li class="text-black hover:text-red-500 " :class="{ 'text-red-500': $route.name === 'Inicio' }" @click="handleNavigation">
        <router-link :to="{ name: 'Inicio' }">Inicio</router-link>
      </li>
      <li
        class="relative text-black hover:text-red-500"
        @click="toggleSubmenu"
      >
        <span>Servicios</span>
        <transition name="slide-down">
          <ul
            v-if="isSubmenuOpen"
            class="absolute bg-white shadow-lg text-left rounded-lg mt-1 p-2 w-48 overflow-y-auto max-h-56"
          >
            <li
              v-for="servicio in getServicios"
              :key="servicio.id"
              class="text-black hover:text-red-500 my-5"
              @click="toggleMenu"
            >
              <router-link
                :to="{ name: 'ServicioDetalle', params: { servicio: servicio.url } }"
                >{{ servicio.nombre }}</router-link
              >
            </li>
          </ul>
        </transition>
      </li>
      <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Nosotros' }" @click="handleNavigation">
        <router-link :to="{ name: 'Nosotros' }">Puccasky</router-link>
      </li>
      <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Proyectos' }" @click="handleNavigation">
        <router-link :to="{ name: 'Proyectos' }">Proyectos</router-link>
      </li>
      <li class="text-black hover:text-red-500" :class="{ 'text-red-500': $route.name === 'Contacto' }" @click="handleNavigation">
        <router-link :to="{ name: 'Contacto' }">Contacto</router-link>
      </li>
    </ul>
    <div class="text-white p-5 flex flex-col justify-start gap-4" @click="handleNavigation">
      <router-link
        :to="{ name: 'Contacto' }"
        class="bg-red-700 px-4 py-2 font-bold rounded-full hover:bg-red-600 text-center"
      >
        Pida una cotización
      </router-link>
      <router-link
        :to="{ name: 'Login' }"
        class="bg-transparent border border-red-700 px-4 py-2 font-bold rounded-full hover:bg-red-600 text-center boton-login-landing"
      >
        Inicia Sesión
      </router-link>
    </div>
  </div>
</nav>
