<template>
  <v-navigation-drawer
    v-bind="$attrs"
    absolute
    left
    temporary
    class="drawer rounded-r-xl"
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        class="pt-7 pl-4"
        active-class="deep-purple--text text--accent-4"
      >
        <p class="drawer__text mb-0">Hola</p>
        <p class="drawer__name">{{ user.username }}</p>
        <v-list-item class="pl-0">
          <v-list-item-title class="drawer__list py-4 d-flex align-center">
            <img class="mr-5" src="@/assets/img/icon/user.svg" alt="">
            Datos del Perfil
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="pl-0">
          <v-list-item-title class="drawer__list py-4 d-flex align-center">
            <img class="mr-5" src="@/assets/img/icon/headphones.svg" alt="">
            Soporte
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="pl-0">
          <v-list-item-title class="drawer__list py-4 d-flex align-center">
            <img class="mr-5" src="@/assets/img/icon/box.svg" alt="">
            Manual de Usuario
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="pl-0">
          <v-list-item-title class="drawer__list py-4 d-flex align-center">
            <img class="mr-5" src="@/assets/img/icon/time-clock.svg" alt="">
            Historial
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="pl-0">
          <v-list-item-title class="drawer__list py-4 d-flex align-center">
            <img class="mr-5" src="@/assets/img/icon/graph.svg" alt="">
            Reporte
          </v-list-item-title>
        </v-list-item>
        <v-spacer/>
        <v-list-item class="pl-0" @click="doLogout">
          <v-list-item-title class="drawer__list py-4 d-flex align-center">
            <img class="mr-5" src="@/assets/img/icon/logout.svg" alt="">
            Cerrar Sesión
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  inheritAttrs: false,
  data () {
    return {
      group: null,
      user: {}
    }
  },
  methods: {
    ...mapActions(['logout']),
    async doLogout () {
      try {
        await this.logout()
      } finally {
        await this.$router.push({ name: 'Welcome' })
      }
    },
    async fetchUser () {
      const userResponse = await axios.get('api/auth/user/')
      const userData = userResponse.data
      this.user = userData
    }
  },
  async mounted () {
    await this.fetchUser()
  }
}
</script>

<style lang="scss" scoped>
.drawer {

  &__text {
    font-size: 1.3rem;
    color: #8A0205;
  }

  &__name {
    font-size: 1.1rem;
    font-weight: 900;
  }

  &__list {
    font-size: 0.9rem;
    line-height: 18px;
    font-weight: 500;
  }
}

</style>
